import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { getPathnameByCategories } from 'lib/utils';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import StopCreatingASetPopup from 'components/common/ModalPopup/popups/StopCreatingASetPopup';
import Icon from 'components/common/Icon';
import HintForJewelry from './components/HintForJewelry';
import HintForDiamond from './components/HintForDiamond';
import { HintForProductSetImpl } from './HintForProductSet.types';

import './HintForProductSet.scss';

interface IProps {
  hintStore: HintForProductSetImpl;
}

const HintForProductSet = ({ hintStore }: IProps) => {
  const { product, customizations, level } = hintStore;

  const history = useHistory();
  const [isPopupVisible, setPopupVisible] = useState(false);

  if (!product) return <></>;

  const handleChange = () => {
    const productPath = getPathnameByCategories(product.categories);
    product && history.push(`${productPath}/${product.id}`, { edit: true });
  };

  return (
    <>
      <div className="hint-product-set">
        <div className="hint-product-set__inner">
          {level === LevelPropductForSet.Parent ? (
            <HintForJewelry
              product={product as RingDetailImpl}
              customizations={customizations}
              handleChange={handleChange}
            />
          ) : (
            <HintForDiamond
              product={product as StoneDetailImpl}
              handleChange={handleChange}
            />
          )}

          <button
            type="button"
            className="hint-product-set__change"
            onClick={handleChange}
          >
            <Icon id="change" />
          </button>
        </div>

        <Icon
          id="close"
          className="hint-product-set__close"
          onClick={() => setPopupVisible(true)}
        />
      </div>

      <StopCreatingASetPopup
        hintStore={hintStore}
        isVisible={isPopupVisible}
        setVisible={setPopupVisible}
      />
    </>
  );
};

export default observer(HintForProductSet);
