import React, { useEffect } from 'react';
import { IObservableValue, observable } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProductDetailPageImpl } from 'pages/ProductDetailPage/ProductDetailPage.types';
import CustomSelect from 'components/form/CustomSelect';
import { ItemsBlockImpl } from 'service/api/api-types/catalog.types';

export interface ICustomisationProperty {
  name: string;
  value: {
    propertyValue: string | number | null;
    propertyName: string;
  };
}

interface ICustomizationItemProps {
  item: ItemsBlockImpl;
  productStore: ProductDetailPageImpl;
  sizeValue: string | number | null;
}

const CustomizationItem = (props: ICustomizationItemProps): JSX.Element => {
  const {
    item,
    productStore,
    productStore: { product, customizations },
    sizeValue,
  } = props;
  const { pathname } = useLocation();
  const history = useHistory();
  const choosedValue: IObservableValue<ICustomisationProperty> =
    observable.box();
  const selectValues =
    item?.customization?.map(({ value, ...rest }) => {
      const valuekey = Object.keys(rest)[0];
      const formatedValue = {
        name: String(value),
        value: {
          propertyValue: valuekey ? rest[valuekey] : null,
          propertyName: valuekey || '',
        },
      };

      if (!valuekey || !rest[valuekey]) {
        choosedValue.set(formatedValue);
      }

      return formatedValue;
    }) || [];
  const defaultSelectedValue = selectValues?.find(
    ({ name }: { name: string | number }) =>
      String(name) === String(sizeValue || product[item.property as 'id']),
  );

  const preselectedCustomizationValue = selectValues?.find(
    ({ name }) => name === customizations?.[item.property],
  );

  const preselectedValue =
    preselectedCustomizationValue || choosedValue.get() || defaultSelectedValue;

  useEffect(() => {
    if (defaultSelectedValue) {
      preselectedCustomizationValue?.value?.propertyValue &&
        productStore.updatePrice(
          +preselectedCustomizationValue?.value?.propertyValue || 0,
          false,
          item.property,
        );
    }
  }, []);

  const handleChange = (
    name: string,
    { value, name: propertyName }: ICustomisationProperty,
  ) => {
    if (value?.propertyName === 'productId' && value.propertyValue) {
      const { search } = history.location;

      productStore.setIsResetEngravingSettings(false);
      history.push({
        pathname: `${pathname.replace(
          String(product.id),
          String(value.propertyValue),
        )}`,
        search,
      });
      return;
    }

    if (value?.propertyName === 'price') {
      value?.propertyValue &&
        productStore.updatePrice(
          +value?.propertyValue || 0,
          false,
          item.property,
        );
      productStore.setCustomization({ name, value: propertyName });
      return;
    }
    if (name !== 'primaryStoneSize') {
      productStore.setCustomization({ name, value: propertyName });
    }
  };

  return (
    <CustomSelect
      defaultValue={sizeValue || ''}
      name={item.property}
      label={item.caption}
      values={selectValues}
      onChange={handleChange}
      emptyValue={preselectedValue?.name || ''}
      oneLine
    />
  );
};

export default observer(CustomizationItem);
