import { makeAutoObservable } from 'mobx';
import { fetchStonesFilterParams } from 'service/api/stones';
import {
  IFilterStonesExtraParams,
  IFilterStonesExtraParamsRequest,
} from '../types';
import { IFilterExtraStonesResponse } from '../../../service/api/api-types/catalog.types';

export const EXTRA_PARAMS: IFilterStonesExtraParamsRequest = {
  filters: {
    price: {},
    carat: {},
    table: {},
    depth: {},
    stoneWidth: {},
    stoneLength: {},
  },
};

export interface IDiamondFiltersExtra {
  filtersExtra: IFilterStonesExtraParams;
  fetchFilterExtraParams: () => Generator<
    Promise<IFilterExtraStonesResponse>,
    IFilterStonesExtraParams,
    IFilterExtraStonesResponse
  >;
}

export default class DiamondFiltersExtra implements IDiamondFiltersExtra {
  filtersExtra: IFilterStonesExtraParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  *fetchFilterExtraParams(): Generator<
    Promise<IFilterExtraStonesResponse>,
    IFilterStonesExtraParams,
    IFilterExtraStonesResponse
  > {
    const { filters } = yield fetchStonesFilterParams(EXTRA_PARAMS);

    this.filtersExtra = (filters as IFilterStonesExtraParams) || {};

    return this.filtersExtra;
  }
}
