import { action, observable, runInAction } from 'mobx';
import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperActivateValidation,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import {
  updateProfile,
  getProfileParams,
  updateProfilePicture,
} from 'service/api/account';
import {
  ProfileParamsResponseImpl,
  UpdateProfilePictureResponseImpl,
} from 'service/api/api-types/account.types';
import {
  validFormControlCountry,
  validFormControlPasswordConfirm,
} from 'lib/validations';
import {
  CustomerImpl,
  ShippingImpl,
  RecipientImpl,
  BillingImpl,
  TProfileFormName,
  UpdateParamsImpl,
} from './accountTypes';
import { USA_COUNTRY_INDEX } from '../../../consts';
import notificationsService from 'service/notifications/notifications.service';
import {
  ACCOUNT_ERRORS,
  SUCCESS_MESSAGES,
} from 'service/notifications/notifications.constants';

export interface ProfileStoreImpl {
  customer: FormGroup<CustomerImpl>;
  shipping: FormGroup<ShippingImpl>;
  recipient: FormGroup<RecipientImpl>;
  billing: FormGroup<BillingImpl>;
  changeParams: (
    formName: TProfileFormName,
    name: string,
    value: string | boolean | number,
  ) => void;
  updateUserPicture: (
    id: number,
    picture: FormData,
  ) => Promise<UpdateProfilePictureResponseImpl | undefined>;
  updateParams: (params: UpdateParamsImpl) => void;
  fetchCurrentProfile: (id: number) => void;
  updateProfileParams: (id: number) => Promise<void>;
}

export default class ProfileStore implements ProfileStoreImpl {
  @observable customer: FormGroup<CustomerImpl> = new FormGroup<CustomerImpl>({
    firstName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('FirstName is required'),
          patternValidator(/^[a-zA-Z]+$/, 'Only letters are allowed'),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    lastName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('LastName is required'),
          patternValidator(/^[a-zA-Z]+$/, 'Only letters are allowed'),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    email: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Email is required'),
          patternValidator(
            /^(\s+)?[a-zA-Z0-9+._-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z]{2,4}([.]{1}[a-zA-Z]{2,4})?(\s+)?$/,
            'Wrong Email format',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    password: new FormControl<string>('', {
      validators: [
        wrapperActivateValidation(
          (state: FormControl) => {
            return !!state.value;
          },
          [
            wrapperSequentialCheck([
              minLengthValidator(6, 'Field minimum length is 6 chars'),
              patternValidator(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,255}$/,
                'Password must contain one uppercase, one lowercase, one number and spec chars',
              ),
              maxLengthValidator(
                255,
                'Field maximum length is 255 chars ',
                ValidationEventTypes.Error,
              ),
              async (state) =>
                validFormControlPasswordConfirm(
                  this.customer?.controls.passwordConfirm,
                  state,
                  true,
                ),
            ]),
          ],
        ),
      ],
    }),
    passwordConfirm: new FormControl<string>('', {
      validators: [
        wrapperActivateValidation(
          (state: FormControl) => {
            return !!state.value || !!this.customer?.controls.password.value;
          },
          [
            wrapperSequentialCheck([
              maxLengthValidator(
                255,
                'Field maximum length is 255 chars ',
                ValidationEventTypes.Error,
              ),
              async (state) =>
                validFormControlPasswordConfirm(
                  state,
                  this.customer?.controls.password,
                ),
            ]),
          ],
        ),
      ],
    }),
    phone: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Phone is required'),
          patternValidator(/^\+/, 'Your phone number should begin from "+"'),
          patternValidator(/^\+[0-9-]+$/, 'Wrong phone format'),
          maxLengthValidator(
            25,
            'Field maximum length is 25 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    country: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Country is required'),
          async (state) =>
            validFormControlCountry(state, 'Country is required'),
        ]),
      ],
    }),
    state: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          async (state) => validFormControlCountry(state, 'State is required'),
        ]),
      ],
    }),
    city: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('City is required'),
          patternValidator(
            /^[a-zA-Z ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    street: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Street is required'),
          patternValidator(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    apt: new FormControl<string>('', {
      validators: [
        wrapperActivateValidation(
          (state: FormControl) => {
            return !!state.value;
          },
          [
            wrapperSequentialCheck([
              patternValidator(
                /^[a-zA-Z0-9 ]+$/,
                'Only letters and digits are allowed',
              ),
              maxLengthValidator(
                20,
                'Field maximum length is 20 chars ',
                ValidationEventTypes.Error,
              ),
              minLengthValidator(1),
            ]),
          ],
        ),
      ],
    }),
    picturePath: new FormControl<string>(''),
  });

  @observable shipping = new FormGroup<ShippingImpl>({
    country: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Country is required'),
          async (state) =>
            validFormControlCountry(state, 'Country is required'),
        ]),
      ],
    }),
    state: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          async (state) => validFormControlCountry(state, 'State is required'),
        ]),
      ],
    }),
    city: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('City is required'),
          patternValidator(
            /^[a-zA-Z ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    street: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Street is required'),
          patternValidator(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    apt: new FormControl<string>('', {
      validators: [
        wrapperActivateValidation(
          (state: FormControl) => {
            return !!state.value;
          },
          [
            wrapperSequentialCheck([
              patternValidator(
                /^[a-zA-Z0-9 ]+$/,
                'Only letters and digits are allowed',
              ),
              maxLengthValidator(
                20,
                'Field maximum length is 20 chars ',
                ValidationEventTypes.Error,
              ),
              minLengthValidator(1),
            ]),
          ],
        ),
      ],
    }),
    zip: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Zip is required'),
          patternValidator(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            50,
            'Field maximum length is 50 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
  });

  @observable recipient = new FormGroup<RecipientImpl>({
    isDifferent: new FormControl<boolean>(false),
    firstName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('FirstName is required'),
          patternValidator(/^[a-zA-Z]+$/, 'Only letters are allowed'),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    lastName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('LastName is required'),
          patternValidator(/^[a-zA-Z]+$/, 'Only letters are allowed'),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    email: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Email is required'),
          patternValidator(
            /^(\s+)?[a-zA-Z0-9+._-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z]{2,4}([.]{1}[a-zA-Z]{2,4})?(\s+)?$/,
            'Wrong Email format',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    phone: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Phone is required'),
          patternValidator(/^\+[0-9-]+$/, 'Wrong phone format'),
          maxLengthValidator(
            25,
            'Field maximum length is 25 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
  });

  @observable billing = new FormGroup<BillingImpl>({
    likeShiping: new FormControl<boolean>(false),
    country: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Country is required'),
          async (state) =>
            validFormControlCountry(state, 'Country is required'),
        ]),
      ],
    }),
    state: new FormControl<number>(0, {
      validators: [
        wrapperSequentialCheck([
          async (state) => validFormControlCountry(state, 'State is required'),
        ]),
      ],
    }),
    city: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('City is required'),
          patternValidator(
            /^[a-zA-Z ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    street: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Street is required'),
          patternValidator(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    apt: new FormControl<string>('', {
      validators: [
        wrapperActivateValidation(
          (state: FormControl) => {
            return !!state.value;
          },
          [
            wrapperSequentialCheck([
              patternValidator(
                /^[a-zA-Z0-9 ]+$/,
                'Only letters and digits are allowed',
              ),
              maxLengthValidator(
                20,
                'Field maximum length is 20 chars ',
                ValidationEventTypes.Error,
              ),
              minLengthValidator(1),
            ]),
          ],
        ),
      ],
    }),
    zip: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Zip is required'),
          patternValidator(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters and digits are allowed',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
  });

  forms = {
    customer: this.customer.controls,
    shipping: this.shipping.controls,
    recipient: this.recipient.controls,
    billing: this.billing.controls,
  };

  @action
  changeParams = async (
    formName: TProfileFormName,
    name: string,
    value: string | boolean | number,
  ): Promise<void> => {
    const formInput = this.forms[formName][name] as FormControl<
      string | boolean | number
    >;

    if (formInput) {
      formInput.value = value;
      if (name === 'country' && value !== USA_COUNTRY_INDEX) {
        // Reset state value for countries which does not have states
        const stateFormInput = this.forms[formName].state as FormControl<
          string | boolean | number | null
        >;
        stateFormInput.value = null;
      }

      await formInput.wait();
    }
  };

  updateUserPicture = async (
    id: number,
    picture: FormData,
  ): Promise<UpdateProfilePictureResponseImpl | undefined> => {
    try {
      const pictureParams = await updateProfilePicture(id, picture);

      runInAction(() => {
        this.customer.controls.picturePath.value = pictureParams.path;
      });
      notificationsService.notifier.success(
        SUCCESS_MESSAGES.PICTURE_UPDATING.message,
        { labels: { warning: SUCCESS_MESSAGES.PICTURE_UPDATING.label } },
      );
    } catch (error: any) {
      notificationsService.notifier.warning(
        ACCOUNT_ERRORS.PICTURE_UPDATING.message,
        { labels: { warning: ACCOUNT_ERRORS.PICTURE_UPDATING.label } },
      );

      return error;
    }
  };

  @action
  updateParams = (params: UpdateParamsImpl): void => {
    Object.keys(params).forEach((formName) => {
      if (!params[formName]) return;

      Object.keys(params[formName]).forEach((paramName) => {
        const form = (this.forms as any)[formName];
        const inputForm = form[paramName];

        if (!inputForm) return;

        if ((params[formName][paramName] || {}).id) {
          inputForm.value = params[formName][paramName].id;
        } else if (params[formName][paramName] !== null) {
          inputForm.value = params[formName][paramName];
        }
      });
    });
  };

  /**
   * Get profile params
   * @param {number} id
   */
  fetchCurrentProfile = async (id: number): Promise<void> => {
    try {
      const params: ProfileParamsResponseImpl = await getProfileParams(id);
      const { shipping, recipient, billing, ...customer } = params;

      this.updateParams({ customer, shipping, recipient, billing });
    } catch (e) {
      notificationsService.notifier.warning(
        ACCOUNT_ERRORS.PROFILE_UPDATING.message,
        { labels: { warning: ACCOUNT_ERRORS.PROFILE_UPDATING.label } },
      );
    }
  };

  updateProfileParams = async (id: number): Promise<void> => {
    const {
      customer,
      shipping,
      recipient: formRecipient,
      billing: formBilling,
    } = this.forms;

    const isBillingSameAsShippingAddress = formBilling.likeShiping.value;
    const isRecipientDifferent = formRecipient.isDifferent.value;

    const recipient = isRecipientDifferent ? formRecipient : customer;
    const billing = isBillingSameAsShippingAddress ? shipping : formBilling;

    const customerState = customer.state.value
      ? { id: customer.state.value }
      : null;
    const shippingState = shipping.state.value
      ? { id: shipping.state.value }
      : null;
    const billingState = billing.state.value
      ? { id: billing.state.value }
      : null;

    try {
      await updateProfile(id, {
        firstName: customer.firstName.value,
        lastName: customer.lastName.value,
        email: customer.email.value,
        password: customer.password.value,
        passwordConfirm: customer.password.value
          ? customer.passwordConfirm.value
          : '',
        phone: customer.phone.value,
        country: {
          id: customer.country.value,
        },
        state: customerState,
        city: customer.city.value,
        street: customer.street.value,
        apt: customer.apt.value,
        picturePath: customer.picturePath.value,
        shipping: {
          country: {
            id: shipping.country.value,
          },
          state: shippingState,
          city: shipping.city.value,
          street: shipping.street.value,
          apt: shipping.apt.value,
          zip: shipping.zip.value,
        },
        recipient: {
          isDifferent: formRecipient.isDifferent.value,
          firstName: recipient.firstName.value,
          lastName: recipient.lastName.value,
          email: recipient.email.value,
          phone: recipient.phone.value,
        },
        billing: {
          likeShiping: formBilling.likeShiping.value,
          country: {
            id: billing.country.value,
          },
          state: billingState,
          city: billing.city.value,
          street: billing.street.value,
          apt: billing.apt.value,
          zip: billing.zip.value,
        },
      });
      notificationsService.notifier.success(
        SUCCESS_MESSAGES.PROFILE_UPDATING.message,
        { labels: { warning: SUCCESS_MESSAGES.PROFILE_UPDATING.label } },
      );
    } catch (e) {
      notificationsService.notifier.warning(
        ACCOUNT_ERRORS.PROFILE_UPDATING.message,
        { labels: { warning: ACCOUNT_ERRORS.PROFILE_UPDATING.label } },
      );
    }
  };
}
