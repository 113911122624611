import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CartStore from 'root/store/cart';
import { allFormValues } from 'root/store/form.store';
import * as cartService from 'service/api/cart';
import { joinClasses } from 'lib/utils';
import * as affirmJS from 'lib/affirm/affirm';
import { ScrollToTopOnMount } from 'components/common/ScrollToTop';
import { LoaderSpinner } from 'components/common/Loaders';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { CartRoutes, PaymentTypes } from '../../constants';
import { GridAreaAmount } from '../GridAreaAmount';
import { PaymentFormsStore } from '../CartCheckout/PaymentStep/paymentForms.store';
import { CustomerFormStore } from '../CartCheckout/CustomerStep/customerForm.store';
import { ShippingFormStore } from '../CartCheckout/ShippingStep/shipping.store';
import CartListItem from '../CartListItem';
import { CustomerOrderBlock } from './CustomerOrderBlock';
import { CartConfirmationStore } from './CartConfirmationStore';
import { DiffRecipientOrderBlock } from './DiffRecipientOrderBlock';
import { PaymentDetailsOrderBlock } from './PaymentDetailsOrderBlock';
import { ShippingAddressOrderBlock } from './ShippingAddressOrderBlock';

import notificationsService from 'service/notifications/notifications.service';

import './CartConfirmation.scss';

interface CartConfirmationPageProps extends RouteComponentProps {
  cart: CartStore;
  store: CartConfirmationStore;
  customerFormStore: CustomerFormStore;
  shippingFormStore: ShippingFormStore;
  paymentFormsStore: PaymentFormsStore;
}

export const CartConfirmation = observer((props: CartConfirmationPageProps) => {
  const {
    history,
    cart,
    store,
    customerFormStore,
    shippingFormStore,
    paymentFormsStore,
  } = props;

  useEffect(() => {
    if (
      customerFormStore.form.invalid ||
      shippingFormStore.form.invalid ||
      paymentFormsStore.billingForm.invalid ||
      ~paymentFormsStore.cardForms.findIndex((form) => form.invalid)
    ) {
      history.push('/cart');
    }
    // Note: for case if change or country
    store.promocode && sendCalculateRequest();

    return () => {
      // Note: reset promocode if move from confirmation page
      store.setPromocode('');
      sendCalculateRequest();
    };
  }, []);

  const resetForms = (): void => {
    customerFormStore.resetForm();
    shippingFormStore.resetForm();
    paymentFormsStore.resetForms();
  };

  const prepareDataAfterRequest = () => {
    const customerParams: any = allFormValues(customerFormStore.form.controls);
    const shippingParams: any = allFormValues(shippingFormStore.form.controls);
    const billingParams: any = allFormValues(
      paymentFormsStore.billingForm.controls,
    );
    const paymentParams: any = allFormValues(paymentFormsStore.form.controls);

    const data = {
      customer: {
        ...customerParams,
        country: { id: Number(customerParams.country) },
        state: { id: Number(customerParams.state) || null },
      },
      delivery: {
        ...shippingParams,
        country: { id: Number(shippingParams.country) },
        state: shippingParams.state
          ? { id: Number(shippingParams.state) }
          : null,
      },
      payment: {
        ...billingParams,
        country: { id: Number(billingParams.country) },
        state: billingParams.state ? { id: Number(billingParams.state) } : null,
        type: paymentParams.paymentType,
        cards: paymentFormsStore.cardForms.map((card) =>
          allFormValues(card.controls),
        ),
      },
    };

    if (!shippingParams.diffRecipient) {
      data.delivery.firstName = data.customer.firstName;
      data.delivery.lastName = data.customer.lastName;
      data.delivery.email = data.customer.email;
      data.delivery.phone = data.customer.phone;
    }

    if (paymentParams.sameShippingAddress) {
      data.payment.city = data.delivery.city;
      data.payment.aptUnit = data.delivery.aptUnit;
      data.payment.zipCode = data.delivery.zipCode;
      data.payment.address = data.delivery.address;
      data.payment.country = data.delivery.country;
      data.payment.state = data.delivery.state;
    }

    return data;
  };

  const handleCheckout = async (
    event: React.MouseEvent<HTMLElement>,
  ): Promise<void> => {
    event.stopPropagation();

    const data = {
      ...prepareDataAfterRequest(),
      promocode: store.promocode,
    };

    store.setDisabled(true);
    store.setLoading(true);

    try {
      if (data.payment.type === PaymentTypes.affirm) {
        const result = await affirmJS.checkout(cart, data);
        if (result?.token) {
          data.payment.affirmToken = result.token;
        } else {
          store.setLoading(false);
          store.setDisabled(false);
          return;
        }
      }

      // TODO: move to action (?)
      const { orderId } = await cartService.checkout(data);
      store.setOrderId(orderId);
      store.changeStatus('SUCCESS');
      store.setPromocode('');

      resetForms();
      cart.clear();
    } catch (e: any) {
      if (e.status === 404) {
        notificationsService.notifier.warning(e.message);
        history.push('/cart');
      } else {
        store.changeStatus('FAILED');
      }
    }

    store.setVisible(!store.isVisible);
    store.setLoading(false);
    store.setDisabled(false);
  };

  const handleApplyPromocode = async (): Promise<any> => {
    store.promocode && sendCalculateRequest();
  };

  const handleChangePromocode = (e: any) => {
    store.setPromocode(e.target.value);
    if (!e.target.value) {
      // Note: reset promocode
      sendCalculateRequest();
    }
  };

  /**
   * Send calculate request with actual data
   */
  const sendCalculateRequest = () => {
    const data = prepareDataAfterRequest();
    cart.calculate({
      customer: { email: data.customer.email },
      delivery: {
        country: data.delivery.country,
        state: data.delivery.state,
      },
      promocode: store.promocode,
    });
  };

  return (
    <div className="checkout-page grid">
      <ScrollToTopOnMount />

      <ul className="grid-area-breadcrumbs">
        <li>
          <Link to="/stones">search inventory</Link>
        </li>
        <li>
          <Link to="/cart">cart</Link>
        </li>
        <li>confirmation</li>
      </ul>

      <div className="grid-area-link"></div>

      <h1 className="grid-area-page-title page-title">confirmation</h1>

      <div className="grid-area-items-list">
        <div className="items-list block-scrollbar">
          {cart.items.map((item) => (
            <CartListItem
              className="cart-item-checkout"
              key={item.guid}
              cartItem={item}
            />
          ))}
        </div>
        <div className="promocode-info-block">
          <div className="grid-area-promocode">
            <div className="promocode-block">
              <input
                className="single-input"
                value={store.promocode}
                onChange={handleChangePromocode}
                type="text"
                name="promocode"
                placeholder="Promocode"
              />
              <div className="btn btn_brown" onClick={handleApplyPromocode}>
                Apply
              </div>
            </div>

            {cart.findWarningByField('promocode') ? (
              <p className="attention-message attention-message_warning attention-message_off-stream attention-message_mt-10">
                {cart.findWarningByField('promocode')}
              </p>
            ) : null}
          </div>

          {cart.discountConfirmed ? (
            <div className="diamond-old-price">{cart.totalWithDiscount}</div>
          ) : null}

          <div className="cart-item__price">
            <p className="cart-item__price-set">Summary</p>

            <h2 className="diamond-price">{cart.total}</h2>
          </div>
        </div>
      </div>

      <div className="grid-area-order-info">
        <div className="order-wrapper block-scrollbar">
          <CustomerOrderBlock
            store={store}
            customerForm={customerFormStore.form}
          />

          <DiffRecipientOrderBlock shippingForm={shippingFormStore.form} />

          <PaymentDetailsOrderBlock
            paymentForm={paymentFormsStore.form}
            cardForms={paymentFormsStore.cardForms}
          />

          <ShippingAddressOrderBlock
            store={store}
            shippingForm={shippingFormStore.form}
          />
        </div>
      </div>

      <div className="grid-area-page-pointer-prev">
        <Link
          to={CartRoutes.Customer}
          className="btn btn_sm btn_text-bold btn_bordered-default btn_fixed-height btn_fixed-width btn_stretch-mobile"
          title="Cart"
        >
          <Icon id="arrow_long" />
          BACK
        </Link>
      </div>

      <GridAreaAmount cart={cart} />

      <div className="grid-area-additional-block">
        <div className="additional-wrap additional-wrap_mb-25">
          <p className="title-label title-label_mr-50 hidden-for-desktop">
            Tax
          </p>
          <p className="attention-message">Subtotal {cart.subtotal}</p>
        </div>
        <div className="additional-wrap additional-wrap_mb-15">
          <p className="title-label hidden-for-desktop">Tax</p>
          <p className="attention-message">Tax {cart.tax}</p>
        </div>
        <div className="additional-wrap additional-wrap_mb-15">
          <p className="title-label hidden-for-desktop">Shipping</p>
          <p className="attention-message">Free shipping</p>
        </div>
      </div>

      <div className="grid-area-page-pointer-next grid-area-page-pointer-next_mt">
        <a
          className={joinClasses([
            'btn btn_brown btn_sm btn-text-bold btn_fixed-height btn_stretch-mobile',
            'order-action',
            store.isDisabled && 'disabled disabled-loading',
          ])}
          title="Checkout"
          onClick={handleCheckout}
        >
          {store.isLoading ? (
            <LoaderSpinner className="loader-spinner_mr-sm" />
          ) : (
            <Icon id="check_icon" />
          )}
          ORDER
        </a>

        <PortalControl>
          <ModalWindow
            visible={store.isVisible}
            closeModal={(e) => {
              const target = e.target as Element;
              if (target === e.currentTarget || target.tagName === 'BUTTON') {
                store.setVisible(false);
                store.statusRequest === 'SUCCESS' && history.push('/');
                store.statusRequest === 'FAILED' && history.push('/cart');
              }
            }}
          >
            <div className="modal-window-header">
              <h2 className="modal-window-header__title">
                {store.statusRequest === 'SUCCESS'
                  ? `Thank you for your order ${store.orderId}!`
                  : store.statusRequest === 'FAILED'
                  ? 'Your Order Could Not Be Processed.'
                  : ''}
              </h2>
            </div>

            <div className="modal-window-body modal-window-scroll-area block-scrollbar">
              {store.statusRequest === 'SUCCESS' ? (
                <div className="modal-window__text modal-window__text_center">
                  A confirmation email has been sent to you. Please allow up to
                  1-2 business days to process this order. If you need to make
                  any changes to your order, please call us within 24 hours at{' '}
                  <span className="bold">+1-877-382-6275</span>.
                </div>
              ) : store.statusRequest === 'FAILED' ? (
                <div className="modal-window__text modal-window__text_center">
                  Your transaction has failed. Please call your financial
                  institution for further details. If you need assistance with
                  your cart or have any questions, please call us at{' '}
                  <span className="bold">+1-877-382-6275</span>.
                </div>
              ) : null}

              <div className="modal-window-btn-wrap">
                <button className="btn btn_lg btn_brown">OK</button>
              </div>
            </div>
          </ModalWindow>
        </PortalControl>
      </div>
    </div>
  );
});
