import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import OrderStore from 'root/store/order';
import PaginateStore from 'root/store/paginate';
import { DiamondFiltersStore } from './diamondFilters.store';
import { fetchFullStones } from 'service/api/stones';
import { TDiamondCatalog } from '../types';
import {
  ICatalogProducts,
  ICatalogQueryParams,
} from 'root/store/products/types/product-catalog.type';
import { IStonesCatalogResponse } from '../../../service/api/api-types/catalog.types';

class DiamondsCatalogStore
  implements ICatalogProducts<TDiamondCatalog, DiamondFiltersStore>
{
  filters!: DiamondFiltersStore;
  order!: OrderStore;
  pagination!: PaginateStore;
  list: IObservableArray<TDiamondCatalog> = observable<TDiamondCatalog>([]);
  loading = false;

  constructor() {
    makeAutoObservable(this);
    this.resetCatalog();
  }

  get queryParams(): ICatalogQueryParams<DiamondFiltersStore> {
    return {
      page: this.pagination.page,
      limit: this.pagination.limit,
      sortBy: this.order.sortBy,
      sortOrder: this.order.sortOrder,
      filters: this.filters.queryParams,
    };
  }

  resetCatalog() {
    this.list.clear();
    this.filters = new DiamondFiltersStore();
    this.order = new OrderStore();
    this.pagination = new PaginateStore();
  }

  putCatalog(products: TDiamondCatalog[] = []) {
    this.list.replace(products);
  }

  updateCatalog(query: ICatalogQueryParams<DiamondFiltersStore>): void {
    this.pagination.setPage(Number(query.page));
    this.filters.update(query.filters);
    this.pagination.setLimit(Number(query.limit));
    this.order.updateOrder(query.sortBy, query.sortOrder);
  }

  *loadProducts(
    query?: ICatalogQueryParams<DiamondFiltersStore>,
  ): Generator<
    Promise<TDiamondCatalog | void> | void,
    void,
    IStonesCatalogResponse
  > {
    try {
      const start = Date.now();
      const params = { ...this.queryParams, ...query };
      const { results, total, totalPages } = yield fetchFullStones(params);
      this.putCatalog(
        results.map((item) => {
          if (item.shape === 'Cushion Modified') {
            return { ...item, shape: 'Cushion' };
          } else return item;
        }),
      );
      this.pagination.setTotalPages(totalPages);
      this.pagination.setTotal(total);
      const delay = Date.now() - start;
      setTimeout(
        () => {
          runInAction(() => {
            this.loading = false;
          });
        },
        delay < 700 ? 700 - delay : 0,
      );
    } catch {
      window.location.reload();
    }
  }
}

export default new DiamondsCatalogStore();
