import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  fetchJewelryDetail,
  fetchJewelryDetailExtra,
} from 'service/api/jewelry';
import { fetchRing, fetchRingExtra } from 'service/api/rings';
import { fetchStone, fetchStoneExtra } from 'service/api/stones';
import { AxiosResponse } from 'axios';
import Currency, { CurrencyImpl } from 'root/store/currency';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import {
  ICurrentProductDetail,
  ProductDetailPageImpl,
  IProductDetailsCustomization,
  IProductDetailsExtraSettings,
  IEngraving,
} from '../ProductDetailPage.types';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import {
  BlocksElementsImpl,
  DetailBlocksImpl,
  ItemsBlockImpl,
  RingDetailResponseData,
} from 'service/api/api-types/catalog.types';

export default class ProductDetailPage implements ProductDetailPageImpl {
  id = 0;
  loading = true;
  isEdit = false;
  product!: ICurrentProductDetail;
  mainBlock?: IObservableArray<ItemsBlockImpl> = observable.array([]);
  detailBlock?: IObservableArray<ItemsBlockImpl> = observable.array([]);
  matchingBand?: RingDetailResponseData | null = null;
  formatPrice = '';
  choosedPrice = 0;
  currency: CurrencyImpl = new Currency();
  customizations?: IProductCustomizations;
  loupeUrl = '';
  settings?: IProductDetailsExtraSettings;
  engraving: IEngraving[] | null = null;
  engravingSettings: any = {};
  isResetEngravingSettings = true;
  isShowEngravingTooltip = false;

  setWithItem: StoneDetailImpl | null = null;
  isViewFromCart = false;

  customDiffPrice: {
    [key: string]: string | number;
  } = {
    size: 0,
    engrave: 0,
    primaryStoneSize: 0,
  };
  _price = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setCustomization({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }): void {
    this.customizations = {
      ...this.customizations,
      [name]: value,
    };
  }

  private removeKeyFromCustomization(key: string) {
    const cloneCustomization = { ...this.customizations };
    delete cloneCustomization[key];
    this.customizations = Object.keys(cloneCustomization).length
      ? cloneCustomization
      : undefined;
  }

  handleSetCustomization = (key: string, value: any): void => {
    let newCustomizationValue;
    if (key === 'engrave') {
      this.engravingSettings = value || {};
    }
    if (typeof value === 'object') {
      const newSettingsForCustomization = Object.values(value).filter(
        (i) => !!i,
      );
      newCustomizationValue = newSettingsForCustomization.length
        ? newSettingsForCustomization
        : undefined;
    } else {
      newCustomizationValue = value;
    }
    if (newCustomizationValue) {
      this.customizations = {
        ...this.customizations,
        [key]: newCustomizationValue,
      };
    } else {
      this.removeKeyFromCustomization(key);
    }
  };

  // @action
  replaceCustomizations = (customizations: any) => {
    this.customizations = customizations;
    this.engravingSettings = customizations?.engrave || {};
    if (this.engravingSettings.length && this.engravingSettings[0]?.price) {
      this.updatePrice(this.engravingSettings[0].price, false, 'engrave');
    } else if (this.engraving) {
      this.updatePrice(0, false, 'engrave');
    }
  };

  setIsResetEngravingSettings(attr: boolean) {
    this.isResetEngravingSettings = attr;
  }

  setIsShowEngravingTooltip = (attr: boolean) => {
    this.isShowEngravingTooltip = attr;
    setTimeout(() => {
      this.isShowEngravingTooltip = false;
    }, 10000);
  };

  updateProductDetail(product: ICurrentProductDetail): void {
    this.id = product.id;
    this.currency.update(product.currency);
    this.product = { ...product };
    this.choosedPrice = Array.isArray(product.price)
      ? +product.price[0]
      : +product.price;

    if (!this.customizations?.size) {
      this.customizations = { ...this.customizations, size: product.size };
    }
    if (this.isEdit) {
      this.setIsEdit(false);
      return;
    }
    this.formatPrice = this.currency.format(
      Array.isArray(product.price) ? product.price[0] : product.price,
    );
  }

  updatePrice(
    priceDiff: number,
    replace?: boolean,
    fromCustomization?: string,
  ): void {
    if (!replace) {
      if (fromCustomization) {
        this.customDiffPrice[fromCustomization] = priceDiff;
        const sum = Object.values(this.customDiffPrice).reduce(
          (a, b) => Number(a) + Number(b),
          0,
        );
        const newPrice = this._price + Number(sum);
        this.formatPrice = this.currency.format(newPrice);

        this.choosedPrice = newPrice;
      }
    } else {
      const newPrice = priceDiff;
      this.formatPrice = this.currency.format(newPrice);

      this.choosedPrice = newPrice;
    }
  }

  resetChoosedPrice() {
    this.choosedPrice = 0;
  }

  private updateDetailBlocks(
    detailBlocks: BlocksElementsImpl[],
    customizationBlocks?: IProductDetailsCustomization[],
  ): void {
    let mainBlock =
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'main')
        ?.items || [];
    let detailBlock =
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'detail')
        ?.items || [];
    let defaultSizePrice: number | string = 0;
    let defaultPrimaryStoneSize: number | string = 0;

    if (customizationBlocks?.length) {
      const customization: Partial<{
        [property: string]: IProductCustomizations[];
      }> = customizationBlocks.reduce(
        (acc, { property, dataSource }) => ({
          ...acc,
          [property]: dataSource,
          id: property,
        }),
        {},
      );

      mainBlock = mainBlock.map((item) => {
        if (customization[item.property]) {
          return { ...item, customization: customization[item.property] };
        }
        return item;
      });

      detailBlock = detailBlock.map((item) => {
        if (customization[item.property]) {
          if (item.property === 'size') {
            defaultSizePrice =
              customization[item.property]?.find(
                (el) => +el.value === +this.product?.size,
              )?.price || 0;
          }
          if (item.property === 'primaryStoneSize') {
            defaultPrimaryStoneSize =
              customization[item.property]?.find(
                (el) => el.value === this.product?.primaryStoneSize,
              )?.price || 0;
          }

          return { ...item, customization: customization[item.property] };
        }
        return item;
      });
    }

    this.mainBlock?.replace(mainBlock);
    this.detailBlock?.replace(detailBlock);

    if (this.product) {
      this._price =
        +this.product.price - +defaultSizePrice - +defaultPrimaryStoneSize;
    }
  }

  private updateMatchingBand(matchingBand?: RingDetailResponseData) {
    this.matchingBand = matchingBand;
  }

  setIsEdit(bool: boolean) {
    this.isEdit = bool;
  }

  async loadProduct(
    apiType: string,
    id: number,
    defaultCustomizationParams: IProductCustomizations | null,
    setWithItems: number[] | null,
  ): Promise<void> {
    id = id || this.id;
    try {
      runInAction(() => {
        this.loading = true;
      });
      const product = await this.getProductDetails(apiType, id);
      if (!product) {
        window.location.href = '/404';
      }
      await this.loadProductExtra(apiType, id);
      runInAction(() => {
        this.updateProductDetail(product);
        this.loading = false;
      });
      setWithItems && (await this.loadStoneForSet('diamonds', setWithItems[0]));
      defaultCustomizationParams &&
        this.replaceCustomizations(defaultCustomizationParams);
    } catch (err) {
      if (err && (err as AxiosResponse).status === 404) {
        window.location.href = '/404';
      }
    }
  }

  private setProductExtra(extra: DetailBlocksImpl) {
    this.updateDetailBlocks(extra.detailBlocks, extra.customization);
    this.updateMatchingBand(extra.matchingBand);
    this.loupeUrl = extra.loupeUrl || '';
    this.engraving = extra.engraving;
    this.settings = extra.settings;
  }

  private async loadProductExtra(apiType: string, id: number) {
    const extra = await this.getProductDetailExtra(apiType, id);
    extra && this.setProductExtra(extra);
  }

  setStoneForSet(stone: StoneDetailImpl | null) {
    this.setWithItem = stone
      ? {
          ...stone,
          formatPrice: this.currency.format(stone.price),
        }
      : null;
  }

  async loadStoneForSet(apiType: string, id: number) {
    const stone = await this.getProductDetails(apiType, id);
    this.setStoneForSet(stone);
    this.setWithItem = {
      ...stone,
      formatPrice: this.currency.format(stone.price),
    };
  }

  async getProductDetails(apiType: string, id: number): Promise<any> {
    switch (apiType) {
      case 'jewelry':
      case 'earrings':
      case 'bracelet':
      case 'pendant':
      case 'necklace':
        return await fetchJewelryDetail(id);
      case 'ring':
      case 'rings':
        return await fetchRing(id);
      case 'diamonds':
        return await fetchStone(id);
      default:
        break;
    }
  }

  getProductDetailExtra = async (apiType: string, id: number) => {
    switch (apiType) {
      case 'jewelry':
        return await fetchJewelryDetailExtra(id);
      case 'ring':
      case 'rings':
        return await fetchRingExtra(id);
      case 'diamonds':
        return await fetchStoneExtra(id);
      default:
        break;
    }
  };

  removeStoneForSet() {
    this.setWithItem = null;
  }

  setViewFromCart(val: boolean) {
    this.isViewFromCart = val;
  }

  resetStore() {
    this.id = 0;
    this.product = null as any;
    this.mainBlock = observable.array([]);
    this.detailBlock = observable.array([]);
    this.formatPrice = '';
    this.choosedPrice = 0;
    this.customizations = {};
    this.engraving = null;
    this.engravingSettings = {};
    this.setWithItem = null;
    this.customDiffPrice = {
      size: 0,
      engrave: 0,
      primaryStoneSize: 0,
    };
    this._price = 0;
  }
}
