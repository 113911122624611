import * as React from 'react';
import { makeAutoObservable } from 'mobx';

export class ShoppingCartStore {
  isLoading = false;
  isVisible = false;
  isShowMobilePanel = false;
  orderBlock: React.RefObject<HTMLInputElement> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  setVisible(value: boolean): void {
    this.isVisible = value;
  }

  showMobilePanelChange(value: boolean): void {
    this.isShowMobilePanel = value;
  }

  setOrderBlock(value: React.RefObject<HTMLInputElement> | null): void {
    this.orderBlock = value;
  }

  get getCoordsOfOrderBlock(): number {
    const block =
      this.orderBlock?.current &&
      this.orderBlock?.current.getBoundingClientRect();
    return (block?.top || 0) + window.pageYOffset;
  }
}

export default new ShoppingCartStore();
