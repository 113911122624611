import axios from './index';
import { AxiosPromise } from 'axios';
import {
  DetailBlocksImpl,
  IFilterExtraRingsResponse,
  RingDetailResponseData,
} from './api-types/catalog.types';
import { TWeddingRingsCatalog } from '../../pages/WeddingRings/types';
import { TJewelryCatalog } from '../../pages/FineJewelry/types';
import { IFilterRingsExtraParamsRequest } from 'pages/EngagementRings/types';

/**
 * API path
 * @type {string}
 */
const pathStone = '/rings';

/**
 * Fetch catalog's rings
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchRings = (
  params: unknown,
): AxiosPromise => // TODO: create type data
  axios.get(`${pathStone}`, { params });

/**
 * Fetch random catalog's rings
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchRandomRings = (
  params: unknown,
): AxiosPromise => // TODO: create type data
  axios.get(`${pathStone}/random`, { params });

/**
 * Fetch full catalog's rings
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchFullRings = (
  params: unknown,
): Promise<TJewelryCatalog | TWeddingRingsCatalog> => // TODO: create type data
  axios.get(`${pathStone}/full`, { params });

/**
 * Fetch ring by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchRing = (id: number): Promise<RingDetailResponseData> =>
  axios.get(`${pathStone}/${id}`);

/**
 * Fetch extra ring by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchRingExtra = (id: number): Promise<DetailBlocksImpl> =>
  axios.get(`${pathStone}/${id}/extra`);

/**
 * Fetch rings extra
 * @returns {AxiosPromise}
 */
export const fetchRingsExtra = (): AxiosPromise =>
  axios.get(`${pathStone}/extra`);

/**
 * Fetch rings filter params
 * @returns {Promise<IFilterRingsJewerlyResponse>}
 */
export const fetchRingsFilterParams = (
  params: IFilterRingsExtraParamsRequest,
): Promise<IFilterExtraRingsResponse> =>
  axios.post(`${pathStone}/extra`, params);
