import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

const useBlockRoutingIfThereIsAnOpenSet = (onBlockCallback: () => boolean) => {
  const history = useHistory();
  const [unblock, setUnblock] = useState<UnregisterCallback | false>(false);

  useEffect(() => {
    const _unblock = history.block((event: any) => {
      const samePathPart =
        event.pathname.indexOf(
          history.location.pathname.split('/catalog')[0],
        ) === 0;
      if (!event.state?.edit && !samePathPart) {
        if (onBlockCallback()) {
          return false;
        }
      }
    });

    setUnblock((unblockPrev) => {
      unblockPrev && unblockPrev();
      return _unblock;
    });

    return () => {
      _unblock && _unblock();
    };
  }, [history.location.pathname, onBlockCallback]);

  return () => {
    if (unblock) {
      unblock();
      history.replace(history.location.pathname);
    }
  };
};

export default useBlockRoutingIfThereIsAnOpenSet;
