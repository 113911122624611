import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import Currency, { CurrencyImpl } from '../currency';
import {
  fetchJewelryDetail,
  fetchJewelryDetailExtra,
} from 'service/api/jewelry';
import {
  BlocksElementsImpl,
  DetailBlocksImpl,
  ItemsBlockImpl,
  JewelryDetailResponseData,
} from 'service/api/api-types/catalog.types';
import { CategoriesProductImpl } from '../../ProductTypes';
import { AxiosResponse } from 'axios';

export interface JewelryDetailImpl {
  // common properties
  id: number;
  sku: string;
  categories: CategoriesProductImpl[];
  currency: CurrencyImpl;
  price: string;
  hasLoupe: boolean;
  loupe: string;
  metal: string;
  outOfStock: boolean;
  photo: string | Array<string>;
  categoryName: string;
  gender: string;
  collection: string;

  // pedants properties
  pendantType: string;

  // earrings properties
  earringType: string;

  // earrings, bracelets and necklaces properties
  closureType: string;

  // necklaces properties
  necklaceLength: string;
  claspType: string;
  chainStyle: string;

  // rings properties
  size: string;
  width: string;
  weight: string;
  profile: string;

  mainBlock: IObservableArray<ItemsBlockImpl> | undefined;
  detailBlock: IObservableArray<ItemsBlockImpl> | undefined;
  [key: string]: any;
}

export interface JewelryImpl extends JewelryDetailImpl {
  updateJewelryDetail: (jewelry: JewelryDetailResponseData) => void;
  reset: () => void;
  loadProduct: (id: number) => Promise<void>;
  loadJewelryDetailExtra: (id: number, hasLoupe: boolean) => Promise<void>;
  updateDetailBlocks: (detailBlocks: BlocksElementsImpl[]) => void;
  _price: string;
}

/**
 * Jewelry detail initial state
 */
export default class JewelryDetailStore implements JewelryImpl {
  // common properties
  id = 0;
  categories = [] as CategoriesProductImpl[];
  sku = '';
  photo: string | Array<string> = [];
  currency: CurrencyImpl = new Currency();
  _price = '';
  hasLoupe = false;
  loupe = '';
  outOfStock = false;
  metal = '';
  categoryName = '';
  gender = '';
  collection = '';

  // pedants properties
  pendantType = '';
  // earrings properties
  earringType = '';
  // earrings, bracelets and necklaces properties
  closureType = '';
  // necklaces properties
  necklaceLength = '';
  claspType = '';
  chainStyle = '';

  // rings properties
  size = '';
  width = '';
  weight = '';
  profile = '';

  mainBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  detailBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  static new(jewelry: JewelryDetailResponseData): JewelryDetailStore {
    const jewelryStore = new JewelryDetailStore();
    jewelryStore.updateJewelryDetail(jewelry);

    return jewelryStore;
  }

  updateJewelryDetail(jewelry: JewelryDetailResponseData): void {
    // common properties update
    this.id = jewelry.id;
    this.sku = jewelry.sku;
    this.categories = jewelry.categories;
    this.photo = jewelry.photo || jewelry.photos;
    this.currency.update(jewelry.currency);
    this._price = jewelry.price;
    this.hasLoupe = jewelry.hasLoupe;
    this.loupe = jewelry.loupe;
    this.metal = jewelry.metal;
    this.gender = jewelry.gender;
    this.categoryName = jewelry.categoryName;
    this.outOfStock = jewelry.outOfStock || false;
    this.collection = jewelry.collection;

    // pedants properties update
    this.pendantType = jewelry.pendantType;

    // earrings properties update
    this.earringType = jewelry.earringType;

    // earrings, bracelets and necklaces properties update
    this.closureType = jewelry.closureType;

    // necklaces properties update
    this.necklaceLength = jewelry.necklaceLength;
    this.claspType = jewelry.claspType;
    this.chainStyle = jewelry.chainStyle;

    // rings properties update
    this.size = jewelry.size;
    this.width = jewelry.width;
    this.weight = jewelry.weight;
    this.profile = jewelry.profile;
  }

  updateDetailBlocks(
    detailBlocks: BlocksElementsImpl[],
    customizationBlocks?: any[],
  ): void {
    let mainBlock =
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'main')
        ?.items || [];
    let detailBlock =
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'detail')
        ?.items || [];

    if (customizationBlocks?.length) {
      const customization = customizationBlocks.reduce(
        (acc, { property, dataSource }) => ({
          ...acc,
          [property]: dataSource,
        }),
        {},
      );

      mainBlock = mainBlock.map((item) => {
        if (customization[item.property]) {
          return { ...item, customization: customization[item.property] };
        }
        return item;
      });

      detailBlock = detailBlock.map((item) => {
        if (customization[item.property]) {
          return { ...item, customization: customization[item.property] };
        }
        return item;
      });
    }

    this.mainBlock?.replace(mainBlock);
    this.detailBlock?.replace(detailBlock);
  }

  reset(): void {
    // common properties reset
    this.id = 0;
    this.sku = '';
    this.photo = [];
    this.categories = [];
    this.currency = new Currency();
    this._price = '';
    this.metal = '';
    this.gender = '';
    this.categoryName = '';
    this.hasLoupe = false;
    this.loupe = '';
    this.outOfStock = false;
    this.collection = '';

    // pedants properties reset
    this.pendantType = '';

    // earrings, bracelets and necklaces properties reset
    this.closureType = '';

    // earrings properties reset
    this.earringType = '';

    // necklaces properties reset
    this.necklaceLength = '';
    this.claspType = '';
    this.chainStyle = '';

    // rings properties reset
    this.size = '';
    this.width = '';
    this.weight = '';
    this.profile = '';
  }

  get price(): string {
    return this.currency.format(this._price);
  }

  /**
   * Update jewelry info
   * @param {Number=} id - jewelry id
   * @returns {Promise<void>}
   */
  async loadProduct(id: number): Promise<void> {
    id = id || this.id;
    try {
      const jewelry = await fetchJewelryDetail(id);
      void this.loadJewelryDetailExtra(id, jewelry.hasLoupe);
      this.updateJewelryDetail(jewelry);
    } catch (err) {
      if (err && (err as AxiosResponse).status === 404) {
        // window.location.href = '/404';
      }
    }
  }

  async loadJewelryDetailExtra(id: number, hasLoupe: boolean): Promise<void> {
    id = id || this.id;
    fetchJewelryDetailExtra(id)
      .then((res: DetailBlocksImpl) => {
        if (hasLoupe && res.loupeUrl) {
          runInAction(() => (this.loupe = res.loupeUrl));
        }

        this.updateDetailBlocks(res.detailBlocks, res.customization);
      })
      .catch(() => {
        runInAction(() => {
          this.outOfStock = true;
        });
      });
  }
}
