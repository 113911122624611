import { action, IObservableArray, observable } from 'mobx';
import { StoneDetailImpl } from './stones/one.store';
import { OrderItemImpl } from './orders/types';
import { StoneDetailResponseData } from 'service/api/api-types/catalog.types';

export interface ListStoreImpl {
  list: OrderItemImpl[] | StoneDetailImpl[];
  reset: () => void;
  put: (list: OrderItemImpl[] | StoneDetailResponseData[]) => void;
}

/**
 * List initial state
 */
export default class ListStore implements ListStoreImpl {
  list: IObservableArray = observable<OrderItemImpl | StoneDetailResponseData>(
    [],
  );

  constructor() {
    this.reset();
  }

  reset = (): void => {
    this.list.clear();
  };

  @action
  put(list: OrderItemImpl[] | StoneDetailResponseData[]): void {
    this.list.replace(list);
  }
}
