import { observable, override } from 'mobx';
import StoneStore, { StoneImpl } from './one.store';
import { fetchStones } from 'service/api/stones';
import ListStore from '../list';
import { StoneDetailResponseData } from 'service/api/api-types/catalog.types';

export interface RandomEarring {
  closureType: string;
  collection: string;
  description: string;
  earringType: string;
  id: number;
  metal: string;
  photos: string[];
  price: string;
}

export interface RandomRings {
  description: string;
  gender: string;
  id: number;
  metal: string;
  photos: string[];
  price: string;
  profile: string;
  size: string;
  sku: string;
  weight: string;
  width: string;
}

export interface RandomBracelets {
  closureType: string;
  collection: string;
  description: string;
  id: number;
  metal: string;
  photos: string[];
  price: string;
}

export type RandomProductType = RandomEarring | RandomRings | RandomBracelets;

export interface StonesListStoreImpl {
  list: StoneImpl[];
  reset: () => void;
  put: (stones: StoneDetailResponseData[]) => void;
  load: (limit: number) => Promise<void>;
  loadRandom: (limit: number) => Promise<void>;
}

/**
 * Stones list initial state
 */
export default class StonesListStore extends ListStore {
  list = observable<StoneImpl>([]);

  @override
  put(stones: StoneDetailResponseData[]): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.list.replace(stones.map(StoneStore.new));
  }

  /**
   * Load stones list
   * @param {Number} limit
   * @returns {Promise<void>}
   */
  async load(limit: number): Promise<void> {
    void fetchStones({ limit }).then((stones) => {
      this.put(stones);
    });
  }

  /**
   * Load random stones list
   * @param {Number} limit
   * @returns {Promise<void>}
   */
}
