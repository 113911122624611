import { makeAutoObservable } from 'mobx';
import * as service from 'service/api/cart';
import { CartCustomerInfoImpl } from 'root/store/cart/item.store';
import { TFormCustomerValues } from './CustomerStep/customerForm.types';
import { TFormPaymentValues } from './PaymentStep/paymentForms.types';
import { TFormValues } from './ShippingStep/shippingForm.types';
import { TGetPaymentProvidersOutput } from 'service/api/apiTypes/cart.types';

interface AutofillStoreReturn {
  value: CartCustomerInfoImpl;
  isEmptyFill: boolean;
  customerFill: TFormCustomerValues;
  deliveryFill: TFormValues;
  paymentFill: TFormPaymentValues;

  fetch(): Generator<Promise<CartCustomerInfoImpl>, void, CartCustomerInfoImpl>;
  fetchPaymentProviders(): Promise<TGetPaymentProvidersOutput[]>;
}

export const autofillStore = (): AutofillStoreReturn =>
  makeAutoObservable({
    value: {} as CartCustomerInfoImpl,
    get isEmptyFill() {
      return !!Object.keys(this.value).length;
    },
    get customerFill() {
      return this.value.customer;
    },
    get deliveryFill() {
      return this.value.delivery;
    },
    get paymentFill() {
      return this.value.payment;
    },
    *fetch() {
      try {
        const autoInfo: CartCustomerInfoImpl = yield service.autofill();
        this.value = autoInfo;
      } catch (e) {
        // console.error(e);
      }
    },
    async fetchPaymentProviders() {
      return await service.getPaymentProviders().catch(() => []);
    },
  });

export const autofill = autofillStore();
