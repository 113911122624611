import axios from './index';
import { ProductBestSellerResponse } from './api-types/catalog.types';
import { ProductInfoImpl } from 'root/ProductTypes';
import { TProductHint } from '../../pages/HintPage/HintPage.types';

/**
 * API path
 * @type {string}
 */
const path = '/products';

/**
 * Fetch catalog's products
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchFavoritesProducts = (
  params: unknown,
): Promise<Array<ProductInfoImpl>> =>
  axios.get(`${path}/favorites`, { params });

/**
 * Fetch bestsellers products
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchBestSellersProducts = (): Promise<
  Array<ProductBestSellerResponse>
> => axios.get(`${path}/best-sellers`);

/**
 * Fetch catalog's products
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchCompareProducts = (
  params: unknown,
): Promise<Array<ProductInfoImpl>> => axios.get(`${path}/compare`, { params });

/**
 * Fetch Hint product by category and id
 */
export const fetchHintProductById = (id: number): Promise<TProductHint> =>
  axios.get(`/products/oneToHint/${id}`);

/**
 * Fetch Global Search results
 * @returns {AxiosPromise}
 * @param q
 */
export const fetchGlobalSearch = (q: string): Promise<ProductInfoImpl[]> => {
  console.log({ params: q });
  return axios.get('/products/search', { params: { q } });
};
