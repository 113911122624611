import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl } from '@quantumart/mobx-form-validation-kit';
import Icon from 'components/common/Icon';
import { joinClasses } from 'lib/utils';
import { UpdateProfilePictureResponseImpl } from '../../../../../service/api/api-types/account.types';

const MAX_SIZE_PICTURE = 500000;
const ERROR_MAX_SIZE_MESSAGE = 'Loaded image size is more than 0.5MB';
const ERROR_UNSUPORTED_TYPE_MESSAGE = 'Unsupported file type';

interface PropsImpl {
  userId: number | null;
  picturePath: FormControl<string>;
  disabled: boolean;
  updateUserPicture: (
    id: number,
    picture: FormData,
  ) => Promise<UpdateProfilePictureResponseImpl | undefined>;
  handleVisibleError: (value: boolean) => void;
  setError500Title: (title: string) => void;
}

function ProfilePicture(props: PropsImpl) {
  const {
    userId,
    picturePath,
    disabled,
    updateUserPicture,
    handleVisibleError,
    setError500Title,
  } = props;
  const [error, setError] = useState('');
  const formData = new FormData();

  useEffect(() => {
    disabled && setError('');
  }, [disabled]);

  const isAllowedDocument = useCallback(
    (file: File) => {
      const isAllowed =
        !!file &&
        file.type &&
        ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);

      if (!isAllowed && file.type) {
        setError(`${ERROR_UNSUPORTED_TYPE_MESSAGE} ${file.type}`);
        return false;
      }

      if (file.size > MAX_SIZE_PICTURE) {
        setError(ERROR_MAX_SIZE_MESSAGE);
        return false;
      }

      return true;
    },
    [setError],
  );

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!userId) return;
    if (!event.target.files) return;

    const file = event.target.files[0];

    if (!isAllowedDocument(file)) return;

    formData.append('files', file);
    setError('');
    const response = await updateUserPicture(userId, formData);

    if (response && response.status === 500) {
      response.title && setError500Title(response.title);
      handleVisibleError(true);
    }
  };

  return (
    <div className="profile-photo-container">
      <div
        className={joinClasses([
          'profile-photo',
          !disabled && 'profile-photo_edit',
          !picturePath.value && 'profile-photo_bg',
        ])}
      >
        {!disabled && (
          <div className="profile-photo-upload">
            <Icon
              id="upload"
              className={
                picturePath.value
                  ? 'profile-photo-upload__icon'
                  : 'profile-photo-upload__icon-center'
              }
            />

            {!picturePath.value && (
              <p className="profile-photo-upload__title">Upload image</p>
            )}
          </div>
        )}

        <label htmlFor="upload-file">
          <input
            type="file"
            id="upload-file"
            name="picturePath"
            accept="image/jpeg,image/jpg,image/png,image/webp"
            onChange={handleUpload}
            disabled={disabled}
          />

          {!picturePath.value ? (
            <img
              className="profile-photo__img"
              src={picturePath.value}
              alt="Customer"
            />
          ) : (
            <Icon id="user" />
          )}
        </label>
      </div>

      {!!error && <div className="profile-photo__error">{error}</div>}
    </div>
  );
}

export default observer(ProfilePicture);
