import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import { CartConfirmationStore } from '../CartConfirmationStore';
import { IFormCustomerStep } from '../../CartCheckout/CustomerStep/customerForm.types';

interface CustomerOrderBlockProps {
  store: CartConfirmationStore;
  customerForm: FormGroup<IFormCustomerStep>;
}

export const CustomerOrderBlock = observer(
  ({ store, customerForm }: CustomerOrderBlockProps) => {
    const country = store.findCountry(+customerForm.controls.country.value);

    return (
      <div className="order-block">
        <h3 className="order-block__title uppercase">customer</h3>
        <table className="order-block__table">
          <tbody>
            <tr>
              <td className="label">First Name</td>
              <td className="value">{customerForm.controls.firstName.value}</td>
            </tr>
            <tr>
              <td className="label">Last Name</td>
              <td className="value">{customerForm.controls.lastName.value}</td>
            </tr>
            <tr>
              <td className="label">Country</td>
              <td className="value">{country?.name}</td>
            </tr>
            {customerForm.controls.state.value ? (
              <tr>
                <td className="label">State</td>
                <td className="value">
                  {
                    store.findState(country)(+customerForm.controls.state.value)
                      ?.name
                  }
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="label">City</td>
              <td className="value">{customerForm.controls.city.value}</td>
            </tr>
            <tr>
              <td className="label">Address</td>
              <td className="value">{customerForm.controls.address.value}</td>
            </tr>
            {customerForm.controls.aptUnit.value ? (
              <tr>
                <td className="label">Apt/Unit</td>
                <td className="value">{customerForm.controls.aptUnit.value}</td>
              </tr>
            ) : null}
            <tr>
              <td className="label">E-mail</td>
              <td className="value">{customerForm.controls.email.value}</td>
            </tr>
            <tr>
              <td className="label">Phone</td>
              <td className="value">{customerForm.controls.phone.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },
);
