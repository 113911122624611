import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { PRODUCT_TYPE_TITLE_LIST } from 'utils/constants';
import { ICategoriesProduct } from 'root/store/products/types/product.type';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { HintForProductSetImpl } from 'components/HintForProductSet/HintForProductSet.types';
import useBlockRoutingIfThereIsAnOpenSet from 'hooks/useBlockRoutingIfThereIsAnOpenSet/useBlockRoutingIfThereIsAnOpenSet';

import './StopCreatingASetPopup.scss';

interface IStopCreatingASetPopupProps {
  hintStore: HintForProductSetImpl;
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const StopCreatingASetPopup = ({
  hintStore,
  isVisible,
  setVisible,
}: IStopCreatingASetPopupProps) => {
  const onBlockCallback = useCallback(() => {
    if (hintStore.level) {
      setVisible(true);
      return true;
    }
    return false;
  }, [hintStore.level]);

  const releaseBlock = useBlockRoutingIfThereIsAnOpenSet(onBlockCallback);

  const handleRemoveSet = () => {
    hintStore.removeSet();
    releaseBlock && releaseBlock();
  };

  const currentCategory =
    hintStore.product?.categories?.find(
      ({ alias }) => PRODUCT_TYPE_TITLE_LIST[alias],
    ) || ({} as ICategoriesProduct);

  return (
    <PortalControl>
      <ModalWindow visible={isVisible}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => setVisible(false)}
        >
          <Icon id="close" />
        </div>
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Are you sure you want to cancel{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]} SKU:
            {hintStore.product?.sku} finishing?
          </h2>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_small modal-window-body__text_center">
            If you will proceed all{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]?.toLowerCase()}{' '}
            data will be lost and{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]?.toLowerCase()}{' '}
            setting will be removed from shopping cart
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              className="send btn btn_lg btn_px-0 btn_brown stop-creating-set-btn"
              onClick={handleRemoveSet}
            >
              Yes
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default observer(StopCreatingASetPopup);
