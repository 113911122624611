import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import { CartConfirmationStore } from '../CartConfirmationStore';
import { IFormShippingStep } from '../../CartCheckout/ShippingStep/shippingForm.types';

interface CustomerOrderBlockProps {
  store: CartConfirmationStore;
  shippingForm: FormGroup<IFormShippingStep>;
}

export const ShippingAddressOrderBlock = observer(
  ({ store, shippingForm }: CustomerOrderBlockProps) => {
    const country = store.findCountry(+shippingForm.controls.country.value);
    return (
      <div className="order-block">
        <h3 className="order-block__title uppercase">shipping address</h3>
        <table className="order-block__table">
          <tbody>
            <tr>
              <td className="label">Country</td>
              <td className="value">{country?.name} </td>
            </tr>
            {shippingForm.controls.state.value ? (
              <tr>
                <td className="label">State</td>
                <td className="value">
                  {
                    store.findState(country)(+shippingForm.controls.state.value)
                      ?.name
                  }
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="label">City</td>
              <td className="value">{shippingForm.controls.city.value}</td>
            </tr>
            <tr>
              <td className="label">Address</td>
              <td className="value">{shippingForm.controls.address.value}</td>
            </tr>
            {shippingForm.controls.aptUnit.value ? (
              <tr>
                <td className="label">Apt/Unit</td>
                <td className="value">{shippingForm.controls.aptUnit.value}</td>
              </tr>
            ) : null}
            <tr>
              <td className="label">ZIP Code</td>
              <td className="value">{shippingForm.controls.zipCode.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },
);
