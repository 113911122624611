import { makeAutoObservable } from 'mobx';
import { fetchJewelryFilterParams } from 'service/api/jewelry';
import {
  IFilterJewerlyExtraParams,
  IFilterJewerlyExtraParamsRequest,
} from '../types';
import { IFilterExtraJewerlyResponse } from '../../../service/api/api-types/catalog.types';

export const EXTRA_PARAMS: IFilterJewerlyExtraParamsRequest = {
  filters: {
    price: {},
  },
};

export interface IJewerlyFiltersExtra {
  filtersExtra: IFilterJewerlyExtraParams;
  fetchFilterExtraParams: () => Generator<
    Promise<IFilterExtraJewerlyResponse>,
    IFilterJewerlyExtraParams,
    IFilterExtraJewerlyResponse
  >;
}

export default class JewerlyFiltersExtra implements IJewerlyFiltersExtra {
  filtersExtra: IFilterJewerlyExtraParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  *fetchFilterExtraParams(): Generator<
    Promise<IFilterExtraJewerlyResponse>,
    IFilterJewerlyExtraParams,
    IFilterExtraJewerlyResponse
  > {
    const { filters } = yield fetchJewelryFilterParams(EXTRA_PARAMS);

    this.filtersExtra = (filters as IFilterJewerlyExtraParams) || {};

    return this.filtersExtra;
  }
}
