import React from 'react';
import { observer } from 'mobx-react';
import rootStore from 'root/store';
import MatchingBandBlock from './MatchingBandBlock';
import { RingDetailResponseData } from 'service/api/api-types/catalog.types';

interface IMatchingBandBlock {
  matchingBand?: RingDetailResponseData | null;
}

const { browserWindowType } = rootStore;

export default observer((props: IMatchingBandBlock) => {
  return <MatchingBandBlock {...props} browserWindowType={browserWindowType} />;
});
