import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import qs from 'qs';
import { useLocation } from 'react-router';
import hintStore from './HintForProductSet.store';
import HintForProductSet from './HintForProductSet';
import { IQueryParamsForSet } from './HintForProductSet.types';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import { convertQueryDataFromBase64 } from 'utils';

export default observer(() => {
  const { search } = useLocation();
  const queryObject = qs.parse(search, {
    ignoreQueryPrefix: true,
    parseArrays: true,
  });
  const { paramsForSet } = queryObject as any;

  useEffect(() => {
    if (!paramsForSet) {
      hintStore.removeSet();
    } else if (paramsForSet.fromItemId) {
      //
      const { fromItemType, fromItemId, customizations, level, price } =
        paramsForSet as IQueryParamsForSet;
      const customizationParams = customizations
        ? convertQueryDataFromBase64(customizations)
        : null;

      hintStore.update({
        customizations: customizationParams as IProductCustomizations,
        level,
        price,
      });
      void hintStore.loadProduct(fromItemType, +fromItemId);
      //
    } else if (paramsForSet.customizations) {
      hintStore.update({
        customizations: paramsForSet.customizations,
      });
    }
  }, [search]);

  if (!paramsForSet) return <></>;

  return <HintForProductSet hintStore={hintStore} />;
});
