import React, { useEffect, MouseEvent, createRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun, IObservableArray } from 'mobx';
import { ROUTER_PATHS } from 'root/routes/constants';
import CartStore from 'root/store/cart';
import { CartItemImpl } from 'root/store/cart/item.store';
import { CountryImpl } from 'root/store/app';
import * as cartService from 'service/api/cart';
import { getPathnameByCategories, joinClasses } from 'lib/utils';
import { ScrollToTopOnMount } from 'components/common/ScrollToTop';
import { LoaderSpinner } from 'components/common/Loaders';
import ModalWindow from 'components/common/ModalPopup';
import PortalControl from 'components/common/PortalControl';
import CartListItem from './components/CartListItem';
import { ShoppingCartStore } from './store';
import { CartRoutes, REMOVE_CHILD } from './constants';

import './ShoppingCart.scss';

interface ShoppingCartProps extends RouteComponentProps {
  store: ShoppingCartStore;
  cart: CartStore;
  countries: IObservableArray<CountryImpl>;
}

export const ShoppingCart = observer(
  ({ cart, store, history }: ShoppingCartProps) => {
    const showHidePanel = (): void => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // For Safari

      store.showMobilePanelChange(
        scrollTop + document.documentElement.clientHeight <
          cart.items.length * 290 + 185,
      );
    };

    const handleScroll = (): void => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      store.showMobilePanelChange(
        scrollTop + document.documentElement.clientHeight <
          store.getCoordsOfOrderBlock,
      );
    };

    useEffect(() => {
      store.setOrderBlock(createRef());

      const disposer = autorun(() => {
        showHidePanel();
      });

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        disposer();
      };
    }, []);

    const handleRemoveItem =
      (guid: string, removeChild: number) =>
      (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();

        cart.remove(guid, removeChild);
      };

    const handlePlaceOrder = async (
      event: MouseEvent<HTMLElement>,
    ): Promise<void> => {
      event.stopPropagation();

      store.setLoading(true);
      try {
        await cartService.placeOrder();
        store.setLoading(false);
        // if (storage.get('accessToken')) {
        // await cart.autofill();
        // }

        history.push(CartRoutes.Customer);
      } catch (e) {
        store.setLoading(false);
        store.setVisible(true);
        console.error(e); // TODO: handle errors with react error component!
        // window.location.reload();
      }
    };

    const handleRedirectToProductDetails =
      (cartItem: CartItemImpl) => (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const productPath = getPathnameByCategories(cartItem.categories);

        history.push(`${productPath}/${cartItem.id}?guid=${cartItem.guid}`);
      };

    const scrollToOrderDetails = (event: MouseEvent<HTMLElement>): void => {
      event.currentTarget.className += ' hidden';

      store.orderBlock &&
        store.orderBlock.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    };

    return (
      <div className="cart-page grid">
        <ScrollToTopOnMount />

        <ul className="grid-area-breadcrumbs">
          <li>
            <Link to="/stones">search inventory</Link>
          </li>
          <li>cart</li>
        </ul>

        <h1 className="grid-area-page-title page-title">cart</h1>

        <div className="grid-area-items-list">
          <div className="items-list block-scrollbar block-scrollbar_margin-right">
            {cart.items.length ? (
              cart.items.map((item) => {
                return (
                  <CartListItem
                    key={item.guid}
                    cartItem={item}
                    handleRemoveItem={handleRemoveItem(item.guid, REMOVE_CHILD)}
                    handleRedirectToProductDetails={handleRedirectToProductDetails(
                      item,
                    )}
                  />
                );
              })
            ) : (
              <p className="attention-message attention-message_text-center attention-message_m-20">
                Your Shopping Cart is empty.
                <br /> Please, go to&nbsp;
                <Link
                  to={{
                    pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                  }}
                  className="common-link"
                >
                  search inventory
                </Link>{' '}
                and select diamonds.
              </p>
            )}
          </div>
        </div>

        <div
          className={joinClasses([
            'mobile-cart-panel',
            store.isShowMobilePanel ? 'shown' : 'hidden',
          ])}
          onClick={scrollToOrderDetails}
        >
          <div className="additional-wrap">
            <p className="title-label">Subtotal</p>
            <p className="attention-message additional__value">
              {cart.subtotal}
            </p>
          </div>
          <div className="additional-wrap">
            <p className="title-label">Tax</p>
            <p className="attention-message additional__value">{cart.tax}</p>
          </div>
          <div className="additional-wrap">
            <p className="title-label">Shipping</p>
            <p className="attention-message additional__value">Free</p>
          </div>

          <h2 className="diamond-amount diamond-amount_small">{cart.total}</h2>
        </div>

        <div ref={store.orderBlock} className="grid-area-amount">
          <div className="price-block">
            <p className="title-label title-label_mb-20">Total Order</p>
            <h2 className="diamond-amount">{cart.total}</h2>
          </div>
        </div>

        <div className="grid-area-additional-block">
          <div className="additional-wrap additional-wrap_mb-20">
            <p className="title-label title-label_mr-15">Subtotal</p>
            <p className="attention-message additional__value">
              {cart.subtotal}
            </p>
          </div>
          <div className="additional-wrap additional-wrap_mb-20">
            <p className="title-label title-label_mr-15">Tax</p>
            <p className="attention-message additional__value">{cart.tax}</p>
          </div>
          <div className="additional-wrap additional-wrap_mb-20">
            <p className="title-label title-label_mr-15">Shipping</p>
            <p className="attention-message additional__value">Free</p>
          </div>
        </div>

        <div className="grid-area-btn-next-page grid-area-btn-next-page_mt">
          <a
            className={`btn btn_brown btn_stretch btn_fixed-height btn_center ${
              !cart.items.length || store.isLoading
                ? 'disabled disabled-loading'
                : ''
            }`}
            title="Move to checkout"
            onClick={handlePlaceOrder}
          >
            {store.isLoading ? (
              <LoaderSpinner className="loader-spinner_mr-sm" />
            ) : (
              ''
            )}
            &nbsp; Proceed to Checkout
          </a>
        </div>

        <PortalControl>
          <ModalWindow
            visible={store.isVisible}
            closeModal={() => {
              store.setVisible(false);
            }}
          >
            <div className="modal-window-header">
              <h2 className="modal-window-header__title">
                Something went wrong.
              </h2>
            </div>
            <div className="modal-window-body modal-window-scroll-area block-scrollbar">
              <div className="modal-window__text modal-window__text_center">
                Try again later or contact customer service by phone{' '}
                <span className="bold">+1-877-382-6275</span>
              </div>
              <div className="modal-window-btn-wrap">
                <button className="btn btn_lg btn_brown">OK</button>
              </div>
            </div>
          </ModalWindow>
        </PortalControl>
      </div>
    );
  },
);
