import * as React from 'react';
import { observer } from 'mobx-react';
import CartStore from 'root/store/cart';

interface IGridAreaAmount {
  cart: CartStore;
}

export const GridAreaAmount = observer(({ cart }: IGridAreaAmount) => {
  return (
    <div className="grid-area-amount">
      <p className="title-label title-label_mb-20">Total Order</p>
      <div className="price-wrap">
        <h2 className="diamond-amount diamond-amount_mb-25">{cart.total}</h2>
        {/* <h2 className="diamond-amount diamond-amount_small diamond-amount_line-through">$ {cart.total}</h2> */}
      </div>
    </div>
  );
});
