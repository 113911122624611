import axios from './index';
import { AxiosPromise } from 'axios';
import {
  DetailBlocksImpl,
  IFilterExtraJewerlyResponse,
  JewelryDetailResponseData,
} from './api-types/catalog.types';
import {
  TJewelryCatalog,
  IFilterJewerlyExtraParamsRequest,
} from '../../pages/FineJewelry/types';

/**
 * API path
 * @type {string}
 */
const pathStone = '/jewelry';

/**
 * Fetch catalog's jewelry
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchJewelry = (
  params: unknown,
): AxiosPromise => // TODO: create type data
  axios.get(`${pathStone}`, { params });

/**
 * Fetch random catalog's jewelry
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchRandomRings = (
  params: unknown,
): AxiosPromise => // TODO: create type data
  axios.get(`${pathStone}/random`, { params });

/**
 * Fetch full catalog's jewelry
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchFullJewelry = (
  params: unknown,
): Promise<TJewelryCatalog> => // TODO: create type data
  axios.get(`${pathStone}/full`, { params });

/**
 * Fetch jewelry by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchJewelryDetail = (
  id: number,
): Promise<JewelryDetailResponseData> => axios.get(`${pathStone}/${id}`);

/**
 * Fetch extra jewelry by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchJewelryDetailExtra = (
  id: number,
): Promise<DetailBlocksImpl> => axios.get(`${pathStone}/${id}/extra`);

/**
 * Fetch jewelry extra
 * @returns {AxiosPromise}
 */
export const fetchJewelryExtra = (): AxiosPromise =>
  axios.get(`${pathStone}/extra`);

/**
 * Fetch jewerly filter params
 * @returns {Promise<IFilterExtraJewerlyResponse>}
 */
export const fetchJewelryFilterParams = (
  params: IFilterJewerlyExtraParamsRequest,
): Promise<IFilterExtraJewerlyResponse> =>
  axios.post(`${pathStone}/extra`, params);
